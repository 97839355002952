#sidebar { transition: all 0.3s; margin-left: 0; position: relative; border-right: 1px solid var(--color-border); }
#sidebar.mobile { margin-left: -600px; }
#sidebar.mobile.active { margin-left: 0; position: absolute; width: 100%; }
#sidebar.mobile.active #sidebar-menu { width: 100%; }

#sidebar-logo, #sidebar-bottom-logo { border-radius: 50%; background-color: rgba(var(--color-sidebar-logo-bcg), 1); margin:25px auto 35px; padding: 8px; width:60px; height: 60px;
  position: relative; display: flex; place-items: center; justify-content: center; box-shadow: inset -2px -2px 4px rgba(0,0,0,0.15); box-sizing: border-box; }
#sidebar-bottom-logo { margin: 10px auto; background-color: #545454; }
#sidebar-logo img, #sidebar-bottom-logo img { max-width: 100%; max-height: 100%;  }
#sidebar-icons svg { stroke-width: 0; }
#sidebar-icons, #sidebar-menu{ position: relative; z-index: 3; }
#sidebar-icons{}
#sidebar-icons button { background: transparent!important; padding: 25px 5px; position: relative; transition: all 0.3s; }
#sidebar-icons button:focus { box-shadow: none!important; }
#sidebar-icons button::before { content: ""; border-radius: 10px; position: absolute; left: 10px; top: 0; width:100%; height:100%; z-index: 1; transition: all 0.2s; }
#sidebar-icons button svg { color: #fff; fill: #fff!important; stroke: #fff!important; z-index: 2; position: relative; }
#sidebar-icons button.active::before, #sidebar-icons button.active:hover::before { background-color: #fff; }
#sidebar-icons button.active svg, #sidebar-icons button.active:hover svg { color: #fff; fill: rgba(var(--color-dark-rgb))!important; stroke: rgba(var(--color-dark-rgb))!important; }

#sidebar-icons button:hover::before { background-color: rgba(0,0,0,0.1); left: 17%; top:8%; width: 70%;height: 80%; }
#sidebar-icons button:hover svg{ color: #fff; fill: #fff!important; stroke: #fff!important; }

#sidebar-menu{}
#sidebar-menu h3{ font-weight: 600!important; text-transform: uppercase; zoom: 0.7; margin-bottom: 40px; }

#sidebar-close { transition: all 0.3s; margin-top: -600px; position: absolute; right: 15px; top: 15px; }
#sidebar-close.mobile { margin-top: 0; }
#sidebar-close button { background-color: transparent!important; }

#sidebar-burger { transition: all 0.3s; margin-top: -600px; position: absolute; right: 15px; top: 15px; z-index: 2; }
#sidebar-burger.mobile { margin-top: 0; }

#sidebar a.nav-link { display:  inline-block; padding: 8px 12px; margin: 2px 0; border-radius: 10px; cursor: pointer; text-decoration: none; color:inherit; transition: background-color 0.2s; }
#sidebar a.nav-link.active, #sidebar a.nav-link:hover { font-weight: 600; background-color: rgba(var(--color-primary-rgb), 0.25); }

/*#sidebar a, #sidebar button { border-radius: 10px; }*/
/*#sidebar a svg, #sidebar button svg {  color: #fff; fill: #fff!important; stroke: #fff!important; }*/