#Helpbar {
  position: absolute;
  right: 0;
  top:0;
  overflow: auto;
  max-height: 100vh;
  z-index: 3;
}
#HelpbarContent {
  overflow: auto;
  max-height: 100vh;
}