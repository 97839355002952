@import 'react-toastify/dist/ReactToastify.css';

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #74bc64;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
}

img { max-width: 100%; max-height:100%; }
h1 { text-transform: uppercase; }
h2, h3, h4 { font-weight: 400!important; }

input, select { background: white!important; }
.App-logo {
  animation: anim-spin infinite 20s linear;
}

.favicon {}

.fullheight {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100)!important;
}

.color-white { filter: brightness(1.2) grayscale(1) contrast(2) brightness(0.2) invert(1) brightness(1.2); }

.text-center { text-align: center!important; }
.text-left { text-align: left!important; }
.text-right { text-align: right!important; }

.overflow-auto { overflow: auto; }
.overflow-hidden { overflow: hidden; }

.text-editable { border-bottom: 2px dashed rgba(var(--color-primary-rgb), 1); cursor: pointer; }

.table-responsive{
  overflow: auto;
  box-sizing: content-box;
  max-height: 90vh;
  width: 100%;
  margin: 30px 0;
  position: relative;
  border: 1px solid rgba(var(--color-border-rgb), 0.2);
}

.table-unclipped{
  max-height: none;
}

.styled-table {
  border-collapse: collapse;
  margin: 0;
  font-size: 0.94em;
  width: 99.9%;
}
.styled-table thead th { text-align: center; }
.styled-table th {
  background-color: rgba(255,255,255, 1);
  border: 1px solid rgba(var(--color-border-rgb), 0.5);
  text-align: left;
  font-weight: bold;
}
.styled-table th,
.styled-table td {
  padding: 10px 12px;
}
.styled-table.compact-table th,
.styled-table.compact-table td {
  padding: 4px 6px;
}
.styled-table tbody tr {
  border-top: 1px solid rgba(var(--color-border-rgb), 0.5);
  border-bottom: 1px solid rgba(var(--color-border-rgb), 0.5);
}
.styled-table tbody tr:nth-of-type(even) {
  background-color: rgba(var(--color-primary-rgb), 0.05);
}
.styled-table .table-cell-highlighted {
  background-color: rgba(var(--color-primary-rgb), 0.25);
  border: 1px solid var(--color-border);
  font-weight: bold;
}
.styled-table .table-cell-important {
  background-color: rgba(var(--color-primary-rgb), 1);
  border: 1px solid var(--color-border);
  color: #fff;
  font-weight: bold;
}

.styled-table th:first-child{
  border-left: none;
}
.styled-table th:last-child{
  border-right: none;
}

.minimal-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  width: 100%;
}
.minimal-table thead th { text-align: center; }
.minimal-table th {
  text-align: left;
}
.minimal-table th,
.minimal-table td {
  padding: 10px 12px;
}
.minimal-table.compact-table th,
.minimal-table.compact-table td {
  padding: 4px 6px;
}
.minimal-table tbody tr {
  border-bottom: 1px solid rgba(var(--color-border-rgb), 0.5);
}
.minimal-table tbody tr:nth-of-type(even) {
  background-color: rgba(var(--color-primary-rgb), 0.05);
}

.minimal-table .table-cell-important {
  background-color: rgba(var(--color-primary-rgb), 1);
  color: #fff;
  border: 1px solid rgba(var(--color-border-rgb), 0.5);
}

#dasboard-umbrella {
  filter: hue-rotate(var(--dashboard-hue-rotate));
}
.styled-table .sticky-header {
  position: sticky;
  top: 0;
}
.styled-table .sticky-header th {
  border: unset;
  box-shadow: 0 0 1px 0.1px rgba(var(--color-border-rgb), 1) inset
}


@keyframes anim-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
